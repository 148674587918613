<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">หมวดหมู่หัตถการ/คอร์ส</h4>
      </div>
      <v-card-title class="pt-0 px-0">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="3">
            <v-text-field
              v-model="filter_search"
              label="พิมพ์คำค้นหา"
              single-line
              hide-details
            >
              <v-icon
                slot="prepend"
                color="#182839"
              >
                mdi-magnify
              </v-icon>
            </v-text-field>
          </v-col>
          <v-spacer/>
          <div class="d-flex align-end justify-end">
            <v-btn
              :loading="loader"
              :disabled="loader"
              color="blue-grey"
              class="my-3 mr-3 white--text"
              @click="addItem"
            >
              <v-icon
                left
                dark
              >
                mdi-plus-circle
              </v-icon>
              เพิ่มหมวดหมู่หัตถการ/คอร์ส
            </v-btn>
          </div>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="data"
        :search="filter_search"
        :loading="loaddata"
        loading-text="กำลังโหลด..."
      >
      <template v-slot:item.operativecourse_status="{ item }">
        <div class="d-flex justify-center align-center">
          <v-switch
            v-model="item.operativecourse_status_bool"
            readonly
            inset
            dense
          ></v-switch>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          icon
          @click="viewItem(item)"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn
          icon
          @click="editItem(item)"
        >
          <v-icon>mdi-file-edit-outline</v-icon>
        </v-btn>
        <v-btn
          icon
          @click="deleteItem(item)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>

      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initData"
        >
          รีเซ็ต
        </v-btn>
      </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="showDialog" max-width="500px">
      <v-card>
        <v-card-title>{{boolAddItems ? 'เพิ่มหมวดหมู่หัตถการ/คอร์ส' : boolViewItems ? 'ข้อมูลหมวดหมู่หัตถการ/คอร์ส' : boolEditItems ? 'แก้ไขข้อมูลหมวดหมู่หัตถการ/คอร์ส' : 'ลบข้อมูลหมวดหมู่หัตถการ/คอร์ส'}}</v-card-title>
        <v-card-text v-if="boolAddItems">
          <v-row class="mt-3 pa-0">
            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ชื่อหมวดหมู่หัตถการ/คอร์ส:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <v-text-field
                v-model="dataDialog.operativecourse_category"
                label="ชื่อหมวดหมู่หัตถการ/คอร์ส"
                placeholder="ระบุชื่อหมวดหมู่หัตถการ/คอร์ส"
                outlined
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="boolViewItems">
          <v-row class="mt-3 pa-0">
            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ชื่อหมวดหมู่หัตถการ/คอร์ส:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7">
              <span>{{dataDialog.operativecourse_category}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">สถานะ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <v-switch
                v-model="dataDialog.operativecourse_status_bool"
                inset
                dense
                readonly
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="boolEditItems">
          <v-row class="mt-3 pa-0">
            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ชื่อหมวดหมู่หัตถการ/คอร์ส:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <v-text-field
                v-model="dataDialog.operativecourse_category"
                label="ชื่อหมวดหมู่หัตถการ/คอร์ส"
                placeholder="ระบุชื่อหมวดหมู่หัตถการ/คอร์ส"
                outlined
                single-line
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">สถานะ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" class="d-flex align-center">
              <v-switch
                v-model="dataDialog.operativecourse_status_bool"
                inset
                dense
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <span>{{`ต้องการลบประเภท ${dataDialog.operativecourse_category || ''} นี้ใช่หรือไม่`}}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="!boolViewItems" color="error" text @click="closeDialog">
            <v-icon v-if="!boolDelItems" class="mr-3">mdi-close-circle</v-icon>
            ยกเลิก
          </v-btn>
          <v-btn
            :color="boolViewItems ? 'blue darken-1' : 'success'"
            text
            @click="submitDialog"
            :disabled="!cansave"
          >
            <v-icon v-if="!boolViewItems && !boolDelItems" class="mr-3">mdi-content-save</v-icon>
            {{boolViewItems ? 'ปิด' : boolAddItems ? 'เพิ่ม' : boolEditItems ? 'บันทึก' : 'ยืนยัน'}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import swal from 'sweetalert'

export default {
  data () {
    return {
      loader: null,
      loaddata: false,
      cansave: true,
      filter_search: '',
      showDialog: false,
      dataDialog: {},
      boolAddItems: false,
      boolViewItems: false,
      boolEditItems: false,
      boolDelItems: false,
      data: []
    }
  },
  computed: {
    headers () {
      return [
        {
          text: '#',
          align: 'center',
          sortable: false,
          value: 'no'
        }, {
          text: 'หมวดหมู่คอร์ส',
          align: 'center',
          sortable: false,
          value: 'operativecourse_category'
        }, {
          text: 'สถานะ',
          align: 'center',
          value: 'operativecourse_status',
          sortable: false
        }, {
          text: 'จัดการ',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      const self = this
      self.fetchData()
    },
    async fetchData () {
      const self = this
      var temp = []
      self.loaddata = true
      temp.push({
        _id: '000001',
        no: 1,
        operativecourse_category: 'Botox',
        operativecourse_status: 'เปิดใช้งาน',
        operativecourse_status_bool: true
      }, {
        _id: '000002',
        no: 2,
        operativecourse_category: 'วิตามินผิว',
        operativecourse_status: 'เปิดใช้งาน',
        operativecourse_status_bool: true
      }, {
        _id: '000003',
        no: 3,
        operativecourse_category: 'ร้อยไหม',
        operativecourse_status: 'เปิดใช้งาน',
        operativecourse_status_bool: true
      }, {
        _id: '000004',
        no: 4,
        operativecourse_category: 'หน้าใส',
        operativecourse_status: 'เปิดใช้งาน',
        operativecourse_status_bool: true
      }, {
        _id: '000005',
        no: 5,
        operativecourse_category: 'หน้าเรียว',
        operativecourse_status: 'เปิดใช้งาน',
        operativecourse_status_bool: true
      }, {
        _id: '000006',
        no: 6,
        operativecourse_category: 'รักษาหลุมสิว',
        operativecourse_status: 'เปิดใช้งาน',
        operativecourse_status_bool: true
      }, {
        _id: '000007',
        no: 7,
        operativecourse_category: 'ลิฟต์หน้า',
        operativecourse_status: 'เปิดใช้งาน',
        operativecourse_status_bool: true
      }, {
        _id: '000008',
        no: 8,
        operativecourse_category: 'ลดกราม',
        operativecourse_status: 'เปิดใช้งาน',
        operativecourse_status_bool: true
      }, {
        _id: '000009',
        no: 9,
        operativecourse_category: 'ลดริ้วรอย',
        operativecourse_status: 'เปิดใช้งาน',
        operativecourse_status_bool: true
      }, {
        _id: '000010',
        no: 10,
        operativecourse_category: 'สลายไขมัน',
        operativecourse_status: 'เปิดใช้งาน',
        operativecourse_status_bool: true
      })
      setTimeout(() => {
        self.data = temp
        self.loaddata = false
      }, 500)
    },
    filterOnlyCapsText (value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
    },
    addItem () {
      const self = this
      self.boolAddItems = true
      self.showDialog = true
    },
    viewItem (item) {
      const self = this
      self.dataDialog = item
      self.boolViewItems = true
      self.showDialog = true
    },
    editItem (item) {
      const self = this
      self.dataDialog = item
      self.boolEditItems = true
      self.showDialog = true
    },
    deleteItem (item) {
      const self = this
      self.dataDialog = item
      self.boolDelItems = true
      self.showDialog = true
    },
    closeDialog () {
      const self = this
      self.boolAddItems = false
      self.boolViewItems = false
      self.boolEditItems = false
      self.boolDelItems = false
      self.showDialog = false
      self.dataDialog = {}
      self.initData()
      self.cansave = true
    },
    async submitDialog () {
      const self = this
      if (self.boolAddItems) {
        if (self.dataDialog.operativecourse_category) {
          const obj = {
            category: self.dataDialog.operativecourse_category
          }
          self.cansave = false
          console.log(obj)
          self.closeDialog()
        } else {
          swal('คำเตือน', 'กรุณากรอกข้อมูลหมวดหมู่หัตถการ/คอร์สให้ครบถ้วน', 'warning', {
            button: false,
            timer: 3000
          })
        }
      } else if (self.boolEditItems) {
        if (self.dataDialog.operativecourse_category) {
          self.closeDialog()
        } else {
          swal('คำเตือน', 'กรุณากรอกข้อมูลหมวดหมู่หัตถการ/คอร์สให้ครบถ้วน', 'warning', {
            button: false,
            timer: 3000
          })
        }
      } else if (self.boolDelItems) {
        self.closeDialog()
      } else {
        self.closeDialog()
      }
    }
  }
}
</script>
